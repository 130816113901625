<template>
<div v-if="data.jjrShow" class="bback" @click="data.jjrShow=false">
</div>
<div v-if="data.jjrShow" class="pop-jjr-div">
  <div class="pop-jjr-con">
    <div class="pop-close" @click="data.jjrShow=false">×</div>
    <img class="pop-top-img" src="@/assets/img/jjr/jjrlmtop.png"/>
    <img class="pop-bg-img" src="@/assets/img/jjr/jjrlmbg.png"/>
  </div>
</div>

</template>
<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import Menu from '../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../common/http.js';
import {isNull,nullStr,showMessage} from '../common/utils.js'
let data=reactive({
  jjrShow:false,
  ewm:""
})

function showJjr(){
    data.jjrShow=true;
}

defineExpose({showJjr})

</script>

<style scoped lang="scss">

.pop-jjr-div{
  position: fixed;
  bottom: 0px;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 99999;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  max-width:30rem;
  top: 50%;
  transform: translate3d(-0%,-50%,0);
  .pop-jjr-con{
    position: relative;
  }
  .pop-close{
    position:absolute;
    padding-left: 0.5rem;
    font-size:1.8rem;
    color:#fff;
  }
  .pop-bg-img{
    width:100%;
  }
  .pop-top-img{
    position:absolute;
    width:6rem;
    left: calc(50% - 3rem);
    top:-3rem;
  }
  

}
</style>