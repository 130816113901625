<template>
  <div class="home">
    <div
      style="
        text-align: center;
        margin-top: 100px;
        text-align: center;
        color: #6c6c6c;
      "
      v-if="data.nodataFalg">
      <img
        src="../../assets/img/bx/xiadanchenggong.png"
        style="margin-top: 100px; width: 15rem; height: 12rem"
      />
      <div>暂无数据</div>
    </div>

    <!--产品列表 开始-->
    <div class="match-product" v-if="data.productList.length>0">
      <div
        class="match-product-item"
        @click="clickProductItem(item)"
        v-for="item in data.productList"
        :key="item.product_code"
        :style="
          'background: url(' +
          item.img_org +
          ');background-size: 100% 100%;height:16rem;padding:0rem;'
        "
      >
        <div v-if="item.flag_hot == '1'" class="item-news">
          <div class="item-news-title">待</div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
          "
        >
          <div style="flex: 1"></div>
          <div
            style="
              background: rgba(0, 0, 0, 0.5);
              height: 6rem;
              border-bottom-left-radius: 1rem;
              border-bottom-right-radius: 1rem;
            "
          >
            <div class="item-top" style="margin: 1rem">
              <img class="item-top-img" :src="item.img_org" />
              <div class="item-top-desc">
                <div class="item-name">
                  <div class="item-name-name">{{ item.product_title }}</div>
                </div>
                <div class="item-hr"></div>
                <!-- <div class="item-warn">最高{{item.money_max}}万</div> -->
                <div class="item-prop">
                  {{ item.summary }}
                  <!-- <div class="item-prop-desc">
                                  最高额度<a class="red">{{item.money_max}}万</a>
                              </div>
                              <div class="item-prop-desc">
                                  年化利率<a class="black">{{item.rate_min}}% - {{item.rate_max}}%</a>
                              </div>
                              <div class="item-prop-desc">
                                  贷款期限<a class="black"></a>
                              </div> -->
                </div>
                <div class="item-back"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--产品列表 结束-->

    <!--服务订单 开始-->
    <div class="match-product"  v-if="data.slist.length>0">
      <div
        class="match-product-item"
        @click="clickServiceItem(item)"
        v-for="item in data.slist"
        :key="item.product.product_code"
        :style="
          'background: url(' +
          item.product.img_org +
          ');background-size: 100% 100%;height:16rem;padding:0rem;'
        "
      >
        <div class="item-news" style="margin:0rem;">
          <div class="item-news-title">{{ data.statusmap[item.status_code] }}</div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
          "
        >
          <div style="flex: 1"></div>
          <div
            style="
              background: rgba(0, 0, 0, 0.5);
              height: 6rem;
              border-bottom-left-radius: 1rem;
              border-bottom-right-radius: 1rem;
            "
          >
            <div class="item-top" style="margin: 1rem">
              <img class="item-top-img" :src="item.product.img_org" />
              <div class="item-top-desc">
                <div class="item-name">
                  <div class="item-name-name">{{ item.product.product_title }}</div>
                </div>
                <div class="item-hr"></div>
                <div class="item-prop">
                  {{ item.desire_time+" "+item.order_address }} 
                </div>
                <div class="item-back"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--服务订单 结束-->

    <!--我的订单 开始-->
    <div class="match-product"  v-if="data.mlist.length>0">
      <div 
        class="match-product-item"
        @click="clickMeItem(item)"
        v-for="item in data.mlist"
        :key="item.product_code"
        :style="
          'background: url(' +
          item.product.img_org +
          ');background-size: 100% 100%;height:16rem;padding:0rem;'
        "
      >
        <div v-if="item.status_code=='0' || item.status_code=='1'"  class="item-news" style="margin:0rem;">
          <div class="item-news-title">{{ data.statusmap[item.status_code] }}</div>
        </div>
        <div v-else  class="item-news item-news2" style="margin:0rem;">
          <div class="item-news-title">{{ data.statusmap[item.status_code] }}</div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
          "
        >
          <div style="flex: 1"></div>
          <div
            style="
              background: rgba(0, 0, 0, 0.5);
              height: 6rem;
              border-bottom-left-radius: 1rem;
              border-bottom-right-radius: 1rem;
            "
          >
            <div class="item-top" style="margin: 1rem">
              <img class="item-top-img" :src="item.product.img_org" />
              <div class="item-top-desc">
                <div class="item-name">
                  <div class="item-name-name">{{ item.product.product_title }}</div>
                </div>
                <div class="item-hr"></div>
                <div class="item-prop">
                  {{ item.product.summary }}
                </div>
                <div class="item-back"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--我的订单 结束-->

    <div style="background: rgb(239, 241, 242)">
      <div class="service">
        <div class="service-title2">保险科技 · 让生活更美好</div>
        <div class="service-title3">移动办公 合规平台 专业服务</div>
      </div>
      <div style="height: 100px"></div>
    </div>
    <!----------菜单开始------------->
    <Menu :emodel="menuIndex"></Menu>

    <KfCard ref="kfcomp"></KfCard>
    <!----------菜单结束------------->
    <JjrCard ref="jjrcomp"></JjrCard>
    <ProductTipCard ref="producttipcomp"></ProductTipCard>
  </div>
</template>

<script setup>
import {} from "vant";
import {
  ref,
  reactive,
  computed,
  watch,
  markRaw,
  defineProps,
  defineExpose,
} from "vue";
import http from "../../common/bxhttp.js";

import Menu from "../../components/Menu.vue";
import { useRoute, useRouter } from "vue-router";
import api from "../../common/http.js";

import { isNull, showMessage, disLabel } from "../../common/utils.js";
// import KfCard from '../../components/KfCard.vue'
import JjrCard from "../../components/JjrCard.vue";
import ProductTipCard from "../../components/ProductTipCard.vue";

const kfcomp = ref(null);
const jjrcomp = ref(null);
const producttipcomp = ref(null);

function showProductTip() {
  producttipcomp.value.showProductTip();
}

function showJjr() {
  jjrcomp.value.showJjr();
}

function showKf() {
  kfcomp.value.showKf();
}

let route = useRoute();
let router = useRouter();
let menuIndex = { index: 1 };

let data = reactive({
  searchTxt: "",
  searchType: "01",
  typeList: [],
  productList: [],
  type: localStorage.getItem("type"),
  slist: [],
  mlist: [],
  nodataFalg: false,
  // statusmap:{"0":"待领取","1":"已领取","2":"已下单","3":"已接单","4":"已完成"},
  statusmap:{"0":"待","1":"领","2":"下","3":"接","4":"完"}
});

let model = reactive({
  phone: "",
  yzm: "",
});

let search = reactive({
  orderType: "",
  typeType: "10",
  typeCode: "",
});

function clickMatch() {
  router.push({ path: "/match" });
}

function clickTypeFun(index) {
  if (index == 1) {
    router.push({ path: "/productlist" });
  } else {
    showProductTip();
  }
}

function clickFun(index) {
  if (index == 1) {
    router.push({ path: "/producttable" });
  } else if (index == 2) {
    clickMatch();
  } else if (index == 3) {
    router.push({ path: "/shop" });
  } else if (index == 5) {
    router.push({ path: "/share" });
  }
}

function clickSearchTitle() {
  if (data.searchType == "01") {
    //查方案
    clickMatch();
  } else if (data.searchType == "02") {
    //查产品
    router.push({ path: "/productlist" });
  } else if (data.searchType == "03") {
    //查进度
    router.push({ path: "/search" });
  }
}

function clickProductItem(item) {
  router.push({ path: "/bxdetail", query: { productCode: item.product_code } });
}

function clickServiceItem(item) {
  router.push({ path: '/bxserviceorderadd',query:{"productCode":item.product_code,"orderCode":item.order_code} })
}

function clickMeItem(item) {
  router.push({ path: '/bxserviceadd',query:{"productCode":item.product_code,"orderCode":item.order_code} })
}

function changeType(type) {
  if (type == "02") {
    router.push({ path: "/productlist" });
  } else if (type == "03") {
    router.push({ path: "/search" });
  }
  // data.searchType=type;
}

function clickSearch(key, value) {
  if (search[key] == value) {
    return;
  }
  search[key] = value;
  searchProduct();
}

function searchBxList() {
  http
    .post("/order/web/product/productlist", { typeType: "10" })
    .then((res) => {
      // console.log(JSON.stringify(res))
      if (res.errcode == "0") {
        if (res.rows.length == 0) {
          data.nodataFalg = true;
        }
        data.productList = res.rows;
      } else {
        showMessage(res.errmsg);
      }
    });
}

function searchOrderMeList() {
  http.post("/zbx/order/ordermelist", {}).then((res) => {
    if (res.errcode == "0") {
      if (res.rows.length == 0) {
        data.nodataFalg = true;
      }
      var list=[];
      for(var i=0;i<res.rows.length;i++){
        if(res.rows[i].product){
          list.push(res.rows[i]);
        }
      }
      data.mlist = list;
    } else {
      showMessage(res.errmsg);
    }
  });
}

function searchOrderServiceList() {
  http.post("/zbx/order/orderservicelist", { searchType: "03" }).then((res) => {
    if (res.errcode == "0") {
      if (res.rows.length == 0) {
        data.nodataFalg = true;
      }
      data.slist = res.rows;
    } else {
      showMessage(res.errmsg);
    }
  });
}

if (data.type == "I") {
  searchBxList();
} else if (data.type == "S") {
  searchOrderServiceList();
} else if (data.type == "U") {
  searchOrderMeList();
}
</script>
<style scoped lang="scss">
.home{
  position: fixed;
  background: rgb(239, 241, 242); 
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
}
.match-product {
  margin-left: 0px;
  margin-right: 0px;
  padding: 1rem;
  background: #eff1f2;
  .match-product-item {
    padding: 1rem;
    background: #fff;
    margin-top: 1rem;
    border-radius: 1rem;

    .item-news {
      border: 30px solid #c90100;
      height: 0;
      width: 0;
      border-right-color: transparent;
      border-bottom-color: transparent;
      position: absolute;
      margin-left: -1rem;
      margin-top: -1rem;
      border-top-left-radius: 1rem;
      .item-news-title {
        margin-left: -1.3rem;
        margin-top: -1.3rem;
        color: #fff;
      }
    }

    .item-news2 {
      border: 30px solid rgb(156, 154, 154);
      border-right-color: transparent;
      border-bottom-color: transparent;
      position: absolute;
    }

    .item-hr {
      height: 1px;
      background: #f3f3f3;
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
    .item-label {
      margin-top: 1rem;
      margin-left: 2rem;
      margin-right: 2rem;
      font-size: 0.9rem;
      .item-label-div {
        margin-bottom: 0.5rem;
        display: flex;
        img {
          width: 1.2rem;
          height: 1.2rem;
        }
        div {
          margin-left: 0.4rem;
        }
      }
    }
    .item-top {
      display: flex;
      .item-top-img {
        width: 4rem;
        height: 4rem;
        border: 1px solid rgba(255, 255, 255, 0.2);
      }
      .item-top-desc {
        margin-left: 1rem;
        flex: 1;
        .item-back {
          margin-top: 0.5rem;
          font-size: 1rem;
          width: 100%;
          .item-back-desc {
            display: inline-block;
            color: #3d6fff;
            border: 1px solid #3d6fff;
            padding-left: 0.3rem;
            padding-right: 0.3rem;
            margin-right: 0.5rem;
            border-radius: 0.3rem;
            margin-bottom: 0.3rem;
          }
        }
        .item-prop {
          color: #e6ecec;
          font-size: 0.9rem;
          width: 85%;
          // white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 2.4rem; /* 最大高度（2行）*/
          -webkit-line-clamp: 2; /* 限制显示的行数 */
          .item-prop-desc {
            display: inline-block;
            margin-right: 1rem;
            margin-top: 0.5rem;
            .red {
              margin-left: 0.2rem;
              color: #c90000;
            }
            .black {
              margin-left: 0.2rem;
              color: #0a1e3c;
            }
          }
        }
        .item-name {
          display: flex;
          .item-name-name {
            color: #fff;
            font-weight: 500;
            overflow: hidden;
            width: 85%;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .item-name-desc {
            margin-left: auto;
            font-size: 0.8rem;
            margin-left: auto;
            color: #3d6fff;
            overflow: hidden;
            width: 85%;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .item-warn {
          background: #fcefe5;
          color: #d21616;
          padding-left: 0.3rem;
          padding-right: 0.3rem;
          border-radius: 0.2rem;
          display: inline;
        }
      }
    }
  }
}
.match-center-type {
  margin: 1rem;
  .match-center-type-name {
    font-size: 0.9rem;
  }
  .match-center-type-type1 {
    overflow-x: auto;
  }
  .match-center-type-type2 {
    // white-space:nowrap;
    font-size: 0.9rem;

    .btn {
      display: inline-block;
      // padding:0.2rem;
      line-height: 1.5rem;
      padding-left: 1rem;
      padding-right: 1rem;

      &.sel {
        background: #3d6fff;
        color: #fff;
        border-radius: 1rem;
      }
    }
  }
  .match-center-type-type {
    display: flex;
    font-size: 0.9rem;

    .ml {
      margin-left: 0.5rem;
    }

    .btn {
      padding-left: 1.3rem;
      padding-right: 1.3rem;
      &.sel {
        background: #3d6fff;
        color: #fff;
        border-radius: 1rem;
      }
    }
    .change-div {
      display: flex;
      margin-left: auto;
      .change-img {
        margin-top: 0.2rem;
        height: 1rem;
      }
      .change-name {
        margin-left: 0.2rem;
        color: #3d6fff;
      }
    }
  }
}

.con-title {
  display: flex;
  margin: 1rem;
  margin-top: 1.5rem;
  .con-title-bg-img {
    margin-left: 1rem;
    flex: 1;
    .con-title-bg-img-img {
      margin-left: auto;
      text-align: right;
      img {
        width: 1.2rem;
      }
    }

    .con-title-bg {
      height: 0.5rem;
      background: #e9ebeb;
      border-radius: 0.5rem;
      border-top-right-radius: 0;
    }
  }
  .con-title-detail {
    font-weight: 800;
    font-size: 1.3rem;
    padding-top: 0.3rem;
    span {
      margin-top: auto;
    }
  }
  .con-title-detail-sub {
    color: #949999;
    margin-top: auto;
    margin-left: 1rem;
    font-size: 0.8rem;
  }
  .con-title-desc {
    margin-left: auto;
    color: #3d6fff;
    font-size: 0.8rem;
  }
}
.dt {
  margin-top: 0.5rem;
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
  color: #fff;
  .dt4 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    .sp {
      margin-top: 0.5rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
      width: 1px;
      height: 0.3rem;
      background: #fff;
    }
  }
  .dt1 {
    text-align: center;
    font-size: 1rem;
  }
  .dt2 {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    &.act {
      font-weight: bold;
      font-size: 1.3rem;
      color: #fff;
    }
  }
  .dt3 {
    &.act {
      height: 0.2rem;
      background: #fff;
      margin-left: 1rem;
      margin-right: 1rem;
      border-radius: 0.15rem;
    }
  }
}

.banner {
  width: 100%;
  height: auto;
}

.search {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
}

.divlight {
  position: absolute;
  display: flex;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  .light {
    width: 3rem;
    height: 2.8rem;
  }
  .light_desc {
    color: #fff;
    margin-left: 1rem;
    .name {
      font-size: 1.2rem;
    }
    .desc {
      font-size: 0.8rem;
    }
  }
  .light_kf {
    margin-left: auto;
    margin-top: 0.5rem;
    img {
      width: 7rem;
    }
  }
}

.app {
  .ml {
    margin-left: 0.5rem;
  }
  .app-con {
    display: flex;
    margin-left: 1rem;
    margin-right: 1rem;

    .app-sp {
      flex: 1;
    }

    .app-item {
      text-align: center;
      // flex:1;
      border-radius: 1rem;
      height: 5rem;
      align-items: center;
      img {
        width: 4rem;
        flex: 1;
      }
      .app-title {
        text-align: center;
        font-size: 0.8rem;
      }
    }
  }
}

.service {
  .ml {
    margin-left: 0.5rem;
  }

  .service-con {
    display: flex;
    margin-left: 1rem;
    margin-right: 1rem;

    .service-item {
      &.bg1 {
        background: url(../../assets/img/index/service_company.png);
        background-size: 100% 100%;
      }
      &.bg2 {
        background: url(../../assets/img/index/service_people.png);
        background-size: 100% 100%;
      }
      &.bg3 {
        background: url(../../assets/img/index/service_flow.png);
        background-size: 100% 100%;
      }
      &.bg4 {
        background: url(../../assets/img/index/service_lock.png);
        background-size: 100% 100%;
      }
      flex: 1;

      border-radius: 1rem;
      display: flex;
      height: 5rem;
      align-items: center;
      img {
        margin-left: 1rem;
        width: 2rem;
        height: 2rem;
      }
      .service-item-title {
        margin-left: 0.5rem;
        .title1 {
          font-size: 1rem;
          font-weight: bold;
        }
        .title2 {
          margin-top: 0.1rem;
          font-size: 0.8rem;
          color: grey;
        }
      }
      // background: url(@/assets/img/index/service_company.png);
    }
  }
  .service-title {
    text-align: center;
    color: #0071bc;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .service-title2 {
    text-align: center;
    color: #848e9d;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    padding-top: 4rem;
  }

  .service-title3 {
    text-align: center;
    color: #949999;
    line-height: 2rem;
    align-items: center;
    margin-left: 1rem;
    font-size: 0.9rem;
    margin-bottom: 2rem;
  }
}

.customer {
  .customer-con {
    margin: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
    .customer-con-div {
      height: 1.8rem;
      padding: 0.5rem;
      display: flex;
      justify-items: center;
      justify-content: center;
      line-height: 1.8rem;
      color: #dcccec;

      .customer-con-div-name {
        display: flex;
        justify-content: center;
        justify-items: center;
        flex: 2;
        img {
          height: 1.4rem;
          margin-top: 0.2rem;
        }
        div {
          vertical-align: middle;
          margin-left: 0.5rem;
        }
      }
      .customer-con-div-phone {
        justify-items: center;
        justify-content: center;
        display: flex;
        flex: 3;
      }
      .customer-con-div-time {
        text-align: center;
        vertical-align: middle;
        flex: 2;
      }

      &.on {
        color: #5000a0;
        background: #dcccec;
        border-radius: 1.3rem;
        .lineh {
          margin-top: 0.4rem;
          width: 1px;
          height: 1rem;
          background: #5000a0;
        }
      }
    }
  }
  .customer-title-sub {
    text-align: center;
    color: #5000a0;
    line-height: 2rem;
    align-items: center;
    font-weight: 500;
    margin-left: 1rem;
    font-size: 1.1rem;
    margin-top: 2rem;
  }
  .customer-title {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .customer-label {
      color: #5000a0;
      line-height: 2rem;
      align-items: center;
      font-weight: 500;
      margin-left: 1rem;
      font-size: 1.1rem;
      a {
        // background: blue;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        color: #fff;
        border-radius: 0.3rem;
        font-size: 1.3rem;
        background: -webkit-linear-gradient(
          left top,
          #5000a0,
          #0071bc
        ); /* Safari 5.1 - 6.0 */
        background: -o-linear-gradient(
          bottom right,
          #5000a0,
          #0071bc
        ); /* Opera 11.1 - 12.0 */
        background: -moz-linear-gradient(
          bottom right,
          #5000a0,
          #0071bc
        ); /* Firefox 3.6 - 15 */
        background: linear-gradient(
          to bottom right,
          #5000a0,
          #0071bc
        ); /* 标准的语法 */
      }
    }
  }
}

.fa-title {
  margin: auto;
  text-align: center;
  font-size: 1.2rem;
  color: #225fb1;
}
.fa {
  margin: 1.2rem;
  .fa-btn {
  }
  .mt {
    margin-top: 0.5rem;
  }
  .fa-div {
    background: #fff;
    // border: 1px solid #949999;
    border-radius: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    .fa-div-label {
      margin: 0.8rem;
      color: #afb4bd;
    }
    img {
      width: 1rem;
      height: 1.3rem;
      vertical-align: middle;
    }
  }

  .fa-div-yzm {
    display: flex;
  }
  .fa-chk {
    img {
      width: 1rem;
    }
  }

  .fa-div-radio {
    margin-top: 1rem;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    .fa-radio {
      width: 0.8rem;
      height: 0.8rem;
      border: 1px solid #ddd;
      border-radius: 50%;
      .fa-sel {
        width: 0.6rem;
        height: 0.6rem;
        margin: 0.1rem;
        border-radius: 50%;
        background: #4372f6;
      }
    }
    .fa-label {
      font-size: 0.9rem;
      margin-left: 0.5rem;
      color: #0a1e3c;
    }
    .fa-label-xy {
      font-size: 0.7rem;
      margin-left: 0.5rem;
      color: #afb4bd;
      a {
        color: #3d6fff;
      }
    }
  }

  .hr {
    background: #ddd;
    height: 1px;
  }

  .van-field__control {
    margin-left: 0.5rem;
  }
}
</style>
