<template>
<div v-if="data.productTipShow" class="bback" @click="data.jjrShow=false">
</div>
<div v-if="data.productTipShow" class="pop-product-div">
  <div class="pop-product-con">
    <div class="pop-close" @click="data.productTipShow=false">×</div>
    <img class="pop-bg-img" src="@/assets/img/index/producttip.png"/>
  </div>
</div>
</template>
<script setup>
import {} from 'vant';
import {ref,reactive,computed,watch,markRaw,defineProps,defineExpose} from "vue"
import Menu from '../components/Menu.vue'
import {useRoute,useRouter} from 'vue-router'
import api from '../common/http.js';
import {isNull,nullStr,showMessage} from '../common/utils.js'
let data=reactive({
  productTipShow:false,
  ewm:""
})

function showProductTip(){
    data.productTipShow=true;
}

defineExpose({showProductTip})

</script>

<style scoped lang="scss">
:deep(.van-popup--center) {
  top: 50%;
  transform: none;
  left: 1rem;
  right: 1rem;
  background: none;
}
.pop-bg-img{
    width: calc(100% - 1rem);
}
.pop-product-div{
  position: fixed;
  bottom: 0px;
  left: 0.5rem;
  right: 0.5rem;
  z-index: 99999;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  max-width:30rem;
  top: 50%;
  transform: translate3d(-0%,-50%,0);
  .pop-product-con{
    position: relative;
  }
  .pop-close{
    position:absolute;
    padding-left: 0.5rem;
    font-size:1.8rem;
    color:#fff;
  }
  .pop-bg-img{
    width:100%;
  }
  

}
</style>